import { NavLink } from 'react-router-dom';
import './SideNavigationBar.scss';

function SideNavigationBar() {
  return (
    <div className='nav-sidebar'>
      <NavLink to='/registrations'>
        <span>Registrations</span>
      </NavLink>
      <NavLink to='/scan'>
        <span>Scan Profile</span>
      </NavLink>
      <NavLink to='/tickets'>
        <span>Tickets</span>
      </NavLink>
    </div>
  );
}

export default SideNavigationBar;
