import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Column, Table, Button } from 'views/components/elements';
import { useTicketStore } from 'store';
import { FlexRow } from 'views/components/styled';
import { CircularProgress, IconButton } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AdvanceSearchDialog from 'views/components/elements/AdvanceSearch';
import { handleApiError } from 'views/shared/utils/error-utils';
import { useNavigate } from 'react-router-dom';

const Pickup = styled('span')(({ theme }) => ({
  color: theme.palette.info.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#e0f7ff',
}));

const Delivery = styled('span')(({ theme }) => ({
  color: theme.palette.warning.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#fff8e1',
}));

function TicketList() {
  const {
    ticketData: { TicketList, TicketPageFilter },
    fetchTickets,
    sortTickets,
  } = useTicketStore();
  const [sortConfig, setSortConfig] = useState<{
    field: string;
    direction: 'asc' | 'desc' | null;
  }>({ field: '', direction: null });
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSortChange = useCallback(
    (field: string, direction: 'asc' | 'desc') => {
      setSortConfig({ field, direction });
      sortTickets(field, direction);
    },
    [sortConfig, sortTickets]
  );

  const columns: Column<any>[] = [
    {
      field: 'name',
      label: 'Name',
      width: 120,
    },
    {
      field: 'phone',
      label: 'Phone',
      width: 120,
    },
    {
      field: 'email_id',
      label: 'Email',
      width: 120,
    },
    {
      field: 'location',
      label: 'Location',
      width: 120,
    },
    {
      field: 'no_of_tickets',
      label: 'No of Tickets',
      sortable: true,
      width: 120,
    },
    {
      field: 'ticket_category',
      label: 'Ticket Category',
      width: 120,
    },
    {
      field: 'total_amount',
      label: 'Total Amount',
      width: 120,
    },
    {
      field: 'ticket_collection_type',
      label: 'Collection type',
      sortable: true,
      valueGetter: row =>
        row.ticket_collection_type === 'Delivery' ? (
          <Delivery>Delivery</Delivery>
        ) : (
          <Pickup>Pickup</Pickup>
        ),
      width: 120,
    },
  ];

  const fetchContent = useCallback(async (page = 1) => {
    setLoading(true);
    const fltr = Object.assign({}, {
      page,
      limit: 10,
    });
    await handleApiError<any>(fetchTickets(fltr));
    setLoading(false);
  }, [fetchTickets]);

  useEffect(() => {
    if (!TicketList.length) {
      fetchContent();
    }
  }, []);

  const handleAdvancedFilterOpen = () => {
    setAdvFilterOpen(true);
  };

  const handleAdvancedSearch = async (values: any) => {
    setAdvFilterOpen(false);
    await handleApiError<any>(fetchTickets(values));
  };

  const handleSearchClose = () => {
    setAdvFilterOpen(false);
  };

  const clearFilter = () => {
    fetchContent();
  };

  const handleTicketClick = (ticket)=>{
    navigate(`/ticket/${ticket[0].ticket_id}`);
  };

  const isSearchParamsExist = Object.entries(TicketPageFilter).some(
    ([key, value]) =>
      key !== 'page' && key !== 'limit' && value !== undefined && value !== ''
  );

  return (
    <>
      <FlexRow>
        <h1>Tickets</h1>
        <IconButton onClick={handleAdvancedFilterOpen}>
          <SearchOutlinedIcon />
        </IconButton>
        {isSearchParamsExist && (
          <Button variant='text' color='secondary' onClick={clearFilter}>
            Clear filters
          </Button>
        )}
      </FlexRow>
      <AdvanceSearchDialog
        onSearch={handleAdvancedSearch}
        onClose={handleSearchClose}
        open={advFilterOpen}
        module = {'ticket'}
      />
      {loading && (
        <div className='spinner'>
          <CircularProgress />
        </div>
      )}
      <Table
        columns={columns}
        rows={TicketList}
        stickyHeader
        infiniteScroll
        sortingField={sortConfig.field}
        sortingOrder={sortConfig.direction}
        onSortChange={handleSortChange}
        onSelectionChange={handleTicketClick }
      />
      
      {!loading && TicketList.length <= 0 && (
        <div style={{ textAlign: 'center', marginTop:'20px'}}>No records found.</div>
      )}
    </>
  );
}

export default TicketList;
