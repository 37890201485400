import * as Yup from 'yup';

export const allocateSlotSchema = Yup.object().shape({
  slot_start_date_time: Yup.date()
    .required('Required'),
  slot_duration: Yup.number()
    .required('Required'),
  location: Yup.string()
    .required('Required'),
});
