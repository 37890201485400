import styled from '@emotion/styled';
import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledVideoContainer = styled('div')({
  width: '100%',
  borderRadius: '5px',
});

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center'
});

const StyledError = styled('p')({
  color: 'red',
});

export const ScanContestant = () => {

  const videoRef = useRef(null);
  const navigate = useNavigate();
  let qrScanner: QrScanner;
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const initQrScanner = async () => {
      if (videoRef.current) {
        qrScanner = new QrScanner(videoRef.current, result => {
          try {
            const data = JSON.parse(result.data);
            if(data.registration_id) {
              qrScanner.stop();
              navigate(`/registration/profile/${data.registration_id}`);
            } else {
              setErrorMessage('Invalid QR, please try again');
            }
          } catch (error) {
            setErrorMessage('Invalid QR, please try again');
          }
        }, {
          returnDetailedScanResult: true,
          highlightScanRegion: true,
        });

        await qrScanner.start();
      }
    };

    initQrScanner();

    return () => {
      if (videoRef.current) {
        qrScanner.stop();
      }
    };
  }, []);

  return (
    <StyledContainer>
      <h3>Scan the QR for Contestant Profile</h3>
      <StyledVideoContainer>
        <video ref={videoRef} style={{ width: '100%', height: '300px' }} />
      </StyledVideoContainer>

      {
        errorMessage ? <StyledError>{errorMessage}</StyledError> : ''
      }
    </StyledContainer>
  );
};
