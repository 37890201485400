import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, Stack } from '@mui/material';
import { Button } from 'views/components/elements';
import { handleApiError } from 'views/shared/utils/error-utils';
import {
  fetchRegistrationDetail,
  updatePaymentStatus,
} from 'gateways/registration';
import WarningCard from 'views/components/elements/WarningCard';
import { Registration } from 'models/Registration';
import styled from '@emotion/styled';
import { FlexColumn, FlexRow } from 'views/components/styled';
import { dateTimeFormat } from 'views/shared/utils/string-utils';
import { Actions } from 'views/shared/StyledComponents';
import ImageModal from 'views/components/ImageModal';

const StyledFlexColumn = styled(FlexColumn)({
  height: '100%',
});

const StyledStack = styled(Stack)({
  overflowY: 'auto',
});

const StyledBox = styled(Box)({
  flexBasis: '40%',
  overflowY: 'scroll',
});

const DetailsContainerBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '150px 1fr',
  color: '#475569',
  gap: '0.8rem',
  ['& .key']: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  ['& .value']: {
    textTransform: 'capitalize',
  },
});

const PaymentContainerBox = styled(DetailsContainerBox)({
  gridTemplateColumns: 'repeat(2, 1fr)',
  alignContent: 'start',
  alignItems: 'center',
});

const StyledFlexRow = styled(FlexRow)({
  gridColumn: '1 / -1',
  marginTop: '3rem',
  justifyContent: 'space-between',
});

const StyledButton = styled(Button)({
  flex: '1 0 auto',
});

const ContestantDetail = () => {
  const navigate = useNavigate();
  const { registration_id } = useParams();
  const [contestantDetail, setContentantDetail] =
    useState<Partial<Registration>>(null);

  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isInitialFetchCompleted = useRef(null);

  const handleError = (error: string) => {
    setErrorMessage(error);
    setIsErrorPopUpOpen(true);
  };

  const fetchData = async () => {
    const { failed, data } = await handleApiError<Registration>(
      fetchRegistrationDetail(registration_id)
    );

    if (failed) {
      return handleError(
        'Failed to fetch the details. Please refresh the page'
      );
    }

    setContentantDetail({
      ...data,
      dob: dateTimeFormat(data.dob, 'date') as string,
      slot_start_date_time: dateTimeFormat(
        data.slot_start_date_time,
        'datetime'
      ) as string,
      created_date_time: dateTimeFormat(
        data.created_date_time,
        'datetime'
      ) as string,
      updated_date_time: dateTimeFormat(
        data.updated_date_time,
        'datetime'
      ) as string,
    });
  };

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      fetchData();
      isInitialFetchCompleted.current = true;
    }
  }, []);

  const handleRedirect = (type: 'back') => () => {
    switch (type) {
    case 'back':
      navigate('/registrations');
      break;
    }
  };

  const renderDetails = (
    fields: (keyof Registration)[],
    isMarried: boolean,
    exclude: boolean
  ) => {
    return (
      <DetailsContainerBox>
        {Object.entries(contestantDetail).map(([key, value]) => {
          const shouldRender = exclude
            ? !fields.includes(key as keyof Registration)
            : fields.includes(key as keyof Registration);
          if (!shouldRender) return null;

          if (isMarried && ['parent_phone', 'parent_name'].includes(key)) {
            key = key.replace('parent', 'guardian');
          }

          if ('created_date_time' === key) {
            key = key.replace('created', 'registered');
          }

          if (['dob'].find((field) => key === field)) {
            key = 'DOB';
          }

          if (['phone'].find((field) => key === field)) {
            key = 'Phone No';
          }

          if (['slot_duration'].find((field) => key === field)) {
            key = 'Slot Duration (mins)';
          }

          if (key === 'venue') {
            value = (
              <Link to={value as string} target='_blank'>
                Venue
              </Link>
            ) as any;
          }

          return (
            <>
              <span className='key'>{key.replace(/_/g, ' ')}</span>
              <span className='value'>
                :{' '}
                {typeof value === 'boolean'
                  ? value
                    ? 'Yes'
                    : 'No'
                  : value || '-'}
              </span>
            </>
          );
        })}
      </DetailsContainerBox>
    );
  };

  const getContestantDetails = () => {
    const fieldsToRemove: (keyof Registration)[] = [
      'UPI_transaction_id',
      'is_payment_received',
      'payment_proof_url',
      'photo_link',
      'registration_id',
      'slot_duration',
      'slot_start_date_time',
      'updated_date_time',
      'contestant_id',
      'venue',
    ];
    const isMarried =
      contestantDetail.marital_status?.toLowerCase() === 'married';

    return renderDetails(fieldsToRemove, isMarried, true);
  };

  const getContestantSlotDetails = () => {
    const fieldsToInclude: (keyof Registration)[] = [
      'slot_duration',
      'slot_start_date_time',
      'venue',
    ];
    const isMarried =
      contestantDetail.marital_status?.toLowerCase() === 'married';

    return renderDetails(fieldsToInclude, isMarried, false);
  };

  const ImagePreview = ({ src, alt, message }) => (
    <Box>
      {src ? (
        <ImageModal src={src} alt={alt} />
      ) : (
        <Box sx={{ ml: 3 }}>{message}</Box>
      )}
    </Box>
  );

  const handlePaymentStatus = (is_payment_received: boolean) => async () => {
    const { failed, data } = await handleApiError<{ contestant_id: number }>(
      updatePaymentStatus(registration_id, is_payment_received)
    );

    if (failed) {
      return handleError(
        'Failed to update the payment status. Please try again.'
      );
    }

    setContentantDetail(prev => ({
      ...prev,
      is_payment_received,
      contestant_id: data?.contestant_id,
    }));
  };

  const getPaymentStatus = (is_payment_received: boolean) => {
    if (is_payment_received === null || is_payment_received === undefined) {
      return 'Not verified';
    }

    return is_payment_received ? 'Received' : 'Not Received';
  };

  const renderPaymentDetail = ({
    is_payment_received,
    UPI_transaction_id,
    contestant_id,
  }: Partial<Registration>) => {
    return (
      <>
        <PaymentContainerBox>
          <span className='key'>UPI Transaction ID</span>
          <span className='value'> : {UPI_transaction_id}</span>
          <span className='key'>Payment Status</span>
          <span className='value'>
            {' '}
            : {getPaymentStatus(is_payment_received)}
          </span>
          {!contestant_id && (
            <StyledFlexRow>
              <StyledButton
                variant='contained'
                color='success'
                onClick={handlePaymentStatus(true)}
              >
                Received
              </StyledButton>
              <StyledButton
                variant='contained'
                color='error'
                onClick={handlePaymentStatus(false)}
              >
                Not Received
              </StyledButton>
            </StyledFlexRow>
          )}
        </PaymentContainerBox>
      </>
    );
  };

  return (
    <StyledFlexColumn>
      <h2>
        Contestant Details{' '}
        {contestantDetail?.contestant_id
          ? `(${contestantDetail.contestant_id})`
          : ''}
      </h2>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
      />
      {contestantDetail?.name ? (
        <>
          <>
            <StyledStack direction='row' spacing={2}>
              <StyledBox>{getContestantDetails()}</StyledBox>
              <Divider
                orientation='vertical'
                flexItem
                style={{ margin: '0 16px' }}
              />
              <Box className='right-side-container' flex={1}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {renderPaymentDetail(contestantDetail)}
                  <ImagePreview
                    src={contestantDetail.payment_proof_url}
                    alt={`${contestantDetail.name}'s payment proof.`}
                    message={`User(${contestantDetail.name}) payment proof missing.`}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <StyledBox>{getContestantSlotDetails()}</StyledBox>
                  <ImagePreview
                    src={contestantDetail.photo_link}
                    alt={`${contestantDetail.name}'s profile image`}
                    message={`User(${contestantDetail.name}) profile is missing.`}
                  />
                </Box>
              </Box>
            </StyledStack>
          </>
          <Actions>
            <Button
              variant='text'
              sx={{
                border: '2px solid #b6b4b4',
                width: '130px',
              }}
              color='secondary'
              onClick={handleRedirect('back')}
            >
              Back
            </Button>
          </Actions>
        </>
      ) : (
        <></>
      )}
    </StyledFlexColumn>
  );
};

export { ContestantDetail };
