export interface Ticket {
  ticket_id: string;
  name: string;
  user_id: string;
  phone: string;
  email_id: string;
  location: string;
  no_of_tickets: string;
  ticket_category: string;
  total_amount: string;
  UPI_transaction_id: string;
  photo_url?: string;
  payment_proof_url?: string;
  QR_code_url: string;
  is_payment_received: boolean;
  ticket_collection_type?: 'Pickup' | 'Delivery' | null
  ticket_delivery_address?: string
  temp_ticket_id: string,
  photo_link?: string
}

export interface TicketListView {
  name: string;
  phone: string;
  email_id: string;
  location: string;
  no_of_tickets: number;
  ticket_category: string;
  total_amount: number;
  is_payment_received: boolean;
}

export interface TicketApiParams {
  query?: string;
  page?: number;
  limit?: number;
  name?: string;
  location?: string;
  phonenumber?: string;
  ticket_category?: ticketCategory;
}

export interface ticketCategory {
  [key: string]: number;
}

export const ticketCategory: ticketCategory = {
  SILVER: 1,
  GOLD: 2,
  PLATINUM: 3,
  DIAMOND: 4,
  VIP: 5,
  VVIP: 6,
  MIP: 7,
};

export const  emptyTicket:Ticket = {
  ticket_id: '',
  name: '',
  user_id: '',
  phone: '',
  email_id: '',
  location: '',
  no_of_tickets: '',
  ticket_category: '',
  total_amount: '',
  UPI_transaction_id: '',
  photo_url: '',
  payment_proof_url: '',
  QR_code_url: '',
  is_payment_received: false,
  ticket_collection_type: null,
  ticket_delivery_address: '',
  temp_ticket_id: ''
};
