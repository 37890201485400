import { Download } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchSummary, getRegistrationReport } from 'gateways/registration';
import { Registration, RegistrationListView } from 'models/Registration';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegistrationStore } from 'store/registration';
import { Button, Column, Table } from 'views/components/elements';
import { Icon } from 'views/components/icon';
import { AllocateSlotDialog } from 'views/dialogs';
import { Actions } from 'views/shared/StyledComponents';
import { handleApiError } from 'views/shared/utils/error-utils';
import {
  base64ToBlob,
  downloadBase64String,
} from 'views/shared/utils/string-utils';
import { DashBoardList } from './Dashboard';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AdvanceSearchDialog from 'views/components/elements/AdvanceSearch';
import { FlexRow } from 'views/components/styled';

const Active = styled('span')(({ theme }) => ({
  color: theme.palette.success.dark,
  padding: '6px 5px',
  borderRadius: '100px',
  backgroundColor: '#defff0',
}));

const NotVerified = styled('span')(({ theme }) => ({
  color: theme.palette.success.dark,
  padding: '6px 5px',
  borderRadius: '100px',
  backgroundColor: '#D3D3D3',
}));

const Selected = styled('span')(({ theme }) => ({
  color: theme.palette.success.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#defff0',
}));

const NotSelected = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#ffebeb',
}));

const WaitingList = styled('span')(({ theme }) => ({
  color: theme.palette.warning.dark,
  padding: '6px 16px',
  borderRadius: '100px',
  backgroundColor: '#FCF75E',
}));

const Inactive = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
  padding: '6px 3px',
  borderRadius: '100px',
  backgroundColor: '#ffebeb',
}));

const RegistrationList = () => {
  const {
    registrationData: {
      registrationList,
      registrationPageFilter,
      hasNextPage,
      fetched,
    },
    setStatusFilter,
    fetchRegistrations,
    selectedRegistration,
    setSelectedRegistration,
    sortRegistration,
  } = useRegistrationStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [advFilterOpen, setAdvFilterOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const initialSummaryData = {
    total_number_of_registrations: 0,
    not_verified_registrations: 0,
    payment_received: 0,
    payment_not_received: 0,
    slot_allocated_registrations: 0,
    slot_not_allocated_registrations: 0,
  };
  const [summaryData, setSummaryData] = useState(initialSummaryData);

  const handleClose = () => {
    setEditorOpen(false);
  };

  const handleSearchClose = () => {
    setAdvFilterOpen(false);
  };

  useEffect(() => {
    (async () => {
      fetchContent();
      setSummaryData(await fetchSummary());
    })();

    setSelectedRegistration([]);
  }, []);

  const columns: Column<Registration>[] = [
    {
      field: 'name',
      label: 'Name',
      width: 120,
    },
    {
      field: 'age',
      label: 'Age',
      width: 120,
    },
    {
      field: 'phone',
      label: 'Phone No',
      width: 120,
    },
    {
      field: 'current_location',
      label: 'Location',
      width: 120,
    },
    {
      field: 'singing_experience',
      label: 'Singing Experience',
      width: 120,
    },
    {
      field: 'is_payment_received',
      label: 'Payment Status',
      sortable: true,
      valueGetter: row =>
        row.is_payment_received ? (
          <Active>Received</Active>
        ) : row.is_payment_received === null ? (
          <NotVerified>Not verified</NotVerified>
        ) :(
          <Inactive>Not Received</Inactive>
        ),
      width: 120,
    },
    {
      field: 'is_selected',
      label: 'Status',
      sortable: true,
      valueGetter: ({ is_selected }) => {
        switch (is_selected) {
        case 'Selected':
          return <Selected>Selected</Selected>;
        case 'Not Selected':
          return <NotSelected>Not Selected</NotSelected>;
        case 'Waiting List':
          return <WaitingList>Waiting List</WaitingList>;
        default:
          return <span></span>;
        }
      },
      width: 120,
    },
    {
      field: 'view',
      label: '',
      width: 120,
      valueGetter: (row: RegistrationListView) => (
        <Button
          variant='text'
          style={{ fontWeight: 'normal', fontSize: '12px' }}
          onClick={() => handleEditClick(row.registration_id)}
        >
          View
        </Button>
      ),
    }
  ];

  const handleSelectionChange = (rows: Registration[]) => {
    const filteredRows = rows.filter(row => 
      !row.slot_start_date_time && !row.slot_duration 
      && row.is_payment_received && row.contestant_id
    );
    setSelectedRegistration(filteredRows);
  };

  const handleEditClick = (registration_id: string) => {
    navigate(`/registration/${registration_id}/view`);
  };

  const fetchContent = useCallback(async (page = 1) => {
    setLoading(true);
    const status = useRegistrationStore.getState().statusFilter;      
    const fltr = Object.assign({}, {
      page,
      limit: 10,
    });
    if(status && status !== ''){
      fltr['status'] = status;
    }
    await handleApiError<any>(fetchRegistrations(fltr));
    setLoading(false);
  }, [useRegistrationStore.getState().statusFilter, fetchRegistrations]);

  const handleNextPage = useCallback(() => {
    fetchContent(registrationPageFilter.page + 1);
  }, [registrationPageFilter.page]);

  const clearFilter = () => {
    fetchContent();
  };
  const handleAdvancedFilterOpen = () => {
    setAdvFilterOpen(true);
  };

  const handleAdvancedSearch = async(values:any) => {
    setAdvFilterOpen(false);
    await handleApiError<any>(fetchRegistrations(values));
  };
  const [sortConfig, setSortConfig] = useState<{
    field: string;
    direction: 'asc' | 'desc' | null;
  }>({ field: '', direction: null });

  const handleSortChange = useCallback(
    (field: string, direction: 'asc' | 'desc') => {
      setSortConfig({ field, direction });
      sortRegistration(field, direction);
    },
    [sortConfig, sortRegistration]
  );

  const isRowDisabled = (row: Registration): boolean => {
    return (!!row.slot_start_date_time && !!row.slot_duration) || (!row.is_payment_received || !row.contestant_id);
  };

  const isSearchParamsExist = Object.entries(registrationPageFilter).some(
    ([key, value]) => key !== 'page' && key !== 'limit' && key !== 'status' && value !== undefined && value !== ''
  );
  const handleStatusFilter = (data) => {
    switch (data) {
    case 'total_number_of_registrations':
      setStatusFilter('');
      break;
    case 'not_verified_registrations':
      setStatusFilter('paymentNotVerified');
      break;
    case 'payment_received':
      setStatusFilter('paymentVerified');
      break;
    case 'payment_not_received':
      setStatusFilter('paymentNotReceived');
      break;
    case 'slot_allocated_registrations':
      setStatusFilter('slotAllocated');
      break;
    case 'slot_not_allocated_registrations':
      setStatusFilter('slotNotAllocated');
      break;
    }
    fetchContent();
  };

  const handleExport = async () => {
    const { headers, data } = await getRegistrationReport(
      registrationPageFilter
    );
    const base64Data = btoa(data);
    const blob = base64ToBlob(base64Data, 'text/plain');
    const url = URL.createObjectURL(blob);

    const fileName = headers['content-disposition'].replace(
      'attachment; filename=',
      ''
    );
    downloadBase64String(url, fileName);

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 5000);
  };

  return (
    <div className='custom-table-wrapper'>
      <div className='head'>
        <FlexRow>
          <h1>Registrations</h1>
          <IconButton onClick={handleAdvancedFilterOpen}>
            <SearchOutlinedIcon />
          </IconButton>
          {isSearchParamsExist && (
            <Button variant='text' color='secondary' onClick={clearFilter}>
              Clear filters
            </Button>
          )}
        </FlexRow>
        <div className='margin-top-2rem'>
          <DashBoardList
            data={summaryData}
            onSelectionChange={handleStatusFilter}
          />
        </div>
      </div>
      <AdvanceSearchDialog
        onSearch={handleAdvancedSearch}
        onClose={handleSearchClose}
        open={advFilterOpen}
        module={'registration'}
      />
      <div className='registration-action'>
        <div>
          <Actions>
            <Button startIcon={<Download />} onClick={handleExport}>
              Export
            </Button>
            <Button
              startIcon={<Icon icon='check_icon' iconValue='check' />}
              disabled={selectedRegistration.length === 0}
              onClick={() => setEditorOpen(true)}
            >
              Allocate Slot ({selectedRegistration.length})
            </Button>
          </Actions>
        </div>
      </div>
      {loading && (
        <div className='spinner'>
          <CircularProgress />
        </div>
      )}
      {
        <Table
          columns={columns}
          rows={registrationList}
          stickyHeader
          selectable
          selected={selectedRegistration}
          onSelectionChange={handleSelectionChange}
          hasMoreRows={hasNextPage}
          infiniteScroll
          onNextPage={handleNextPage}
          sortingField={sortConfig.field}
          sortingOrder={sortConfig.direction}
          onSortChange={handleSortChange}
          isRowDisabled={isRowDisabled}
        />
      }

      <AllocateSlotDialog open={editorOpen} onClose={handleClose} />

      {!loading && fetched && registrationList.length <= 0 && (
        <div style={{ textAlign: 'center' }}>No records found.</div>
      )}
    </div>
  );
};

export default RegistrationList;
