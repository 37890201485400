import styled from '@emotion/styled';
import { Tabs } from '@mui/material';

const FormField = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 4,
  '& label': {
    fontSize: 14,
  },
});

const Required = styled('span')({
  color: 'rgb(220 33 21)',
});

const Actions = styled('div')({
  display: 'flex',
  columnGap: 32,
  marginTop: 32,
});

const CustomTabs = styled(Tabs)({
  '& .MuiTab-root': {
    color: '#1D4ED8 !important',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#0F172A !important',
  },
  '& .MuiTab-root.Mui-selected': {
    color: '#0F172A !important',
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0,
  },
});

export { FormField, Required, Actions, CustomTabs };
