import { ApiParams } from 'gateways/registration';
import { TicketApiParams } from 'models';
import { useTicketStore } from 'store';
import { useRegistrationStore } from 'store/registration';

export const getAdvancedSearchInitialValues = (
  module: 'ticket' | 'registration'
) => {
  const {
    registrationData: { registrationPageFilter },
  } = useRegistrationStore();
  const {
    ticketData: { TicketPageFilter },
  } = useTicketStore();
  const RegistrationFieldsToCheck = [
    'name',
    'location',
    'phonenumber',
    'referalCode',
    'contestent_id',
    'status'
  ];

  const TicketFieldsToCheck = [
    'name',
    'location',
    'phonenumber',
    'ticket_category',
  ];

  const fieldsToCheck =
    module === 'registration' ? RegistrationFieldsToCheck : TicketFieldsToCheck;
  const registrationInitialValues = {
    ...registrationPageFilter, // Spread the existing object
    ...fieldsToCheck.reduce((acc, field) => {
      // Add the field with '' if it's missing or has an empty string
      if (
        !(field in registrationPageFilter) ||
        registrationPageFilter[field as keyof ApiParams] === ''
      ) {
        acc[field] = '';
      }
      return acc;
    }, {} as ApiParams),
  };
  const ticketInitialValues = {
    ...TicketPageFilter, // Spread the existing object
    ...fieldsToCheck.reduce((acc, field) => {
      // Add the field with '' if it's missing or has an empty string
      if (
        !(field in TicketPageFilter) ||
        TicketPageFilter[field as keyof TicketApiParams] === ''
      ) {
        acc[field] = '';
      }
      return acc;
    }, {} as TicketApiParams),
  };

  const initialValues =
    module === 'registration' ? registrationInitialValues : ticketInitialValues;
  return initialValues;
};

export const getAdvancedSearchFields = (module: 'ticket' | 'registration') => {
  const registrationFields = [
    { name: 'name', label: 'Name' },
    { name: 'location', label: 'Location' },
    { name: 'phonenumber', label: 'Phone Number', type: 'number' },
    { name: 'status', label: 'Status' },
    { name: 'referalCode', label: 'Referal Code' },
    { name: 'contestent_id', label: 'Contestent Id' },
  ];

  const ticketFields = [
    { name: 'name', label: 'Name' },
    { name: 'location', label: 'Location' },
    { name: 'phonenumber', label: 'Phone Number', type: 'number' },
    { name: 'ticket_category', label: 'Ticket Category' },
  ];
  return module === 'registration' ? registrationFields : ticketFields;
};
