import { Button, Dialog, DialogTitle, IconButton } from '@mui/material';
import { Actions, Content, FormField, Head } from './Elements';
import CloseIcon from '@mui/icons-material/Close';
import { Form, Input } from 'views/components/elements';
import { useRegistrationStore } from 'store/registration';
import { allocateSlotSchema } from 'schema/AllocateSlotSchema';
import styled from '@emotion/styled';
import { allocateSlot, checkSlotAvailability } from 'gateways/registration';
import { handleApiError } from 'views/shared/utils/error-utils';
import AlertNotifier, { Position } from 'views/components/elements/AlertNotifier';
import { useState } from 'react';
import moment from 'moment';


type AllocateSlotDialogProps = {
  open: boolean;
  onClose: () => void;
}

export type AllocateSlotParams = {
  registrations: string[]
  slot_start_date_time: string,
  slot_duration: number,
  location: string
}

const SyledError = styled('div')({
  color: 'red',
  fontSize: '12px'
});

export default function AllocateSlotDialog(props: AllocateSlotDialogProps) {

  const { selectedRegistration, setSelectedRegistration } = useRegistrationStore();
  const [alertOpen, setAlertOpen] = useState(false);
  const alertPosition: Position =  { vertical: 'top', horizontal: 'right' };
  const [alert, setAlert] = useState(null);
  const [slotAvailable, setSlotAvailable] = useState(false);

  const registration = {
    slot_start_date_time: '',
    slot_duration: '',
    location: ''
  };

  const handleCloseAlert = () => {
    setAlertOpen(false); 
  };

  const handleOnSlotSelect = async (e) => {
    const formatedDate = moment(e.target.value).format('yyyy-MM-DD HH:mm:ss');
    const { failed, data: response } = await handleApiError<any>(checkSlotAvailability(formatedDate));

    if(response !== 0 && !failed) {
      setAlert({
        message: `This slot is already occupied by ${response} contestant(s)`,
        severity: 'error',
      });
      setAlertOpen(true);
      setSlotAvailable(false);
    } else if(response === 0 && !failed) {
      setSlotAvailable(true);
    }
  };

  const handleSubmit = async (values) => {
    const formatedDate = moment(values.slot_start_date_time).format('yyyy-MM-DD HH:mm:ss');
    const data: AllocateSlotParams = {
      registrations: selectedRegistration.map(registration => registration.registration_id),
      slot_start_date_time: formatedDate,
      slot_duration: values.slot_duration,
      location: values.location
    };

    if (slotAvailable) {
      const response = await allocateSlot(data);
      if(!response?.succeeded && response !== '') {
        setAlert({
          message: response.errors[0]?.description,
          severity: 'warning',
        });
        setAlertOpen(true);
        return;
      } else {
        setSelectedRegistration([]);
        props.onClose();
      }
    } else {
      setAlert({
        message: 'Please Select the valid slot',
        severity: 'warning',
      });
      setAlertOpen(true);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth='sm' fullWidth>
      <Head>
        <DialogTitle>Allocate Slot</DialogTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </Head>
      <Form
        initialValues={registration}
        onSubmit={handleSubmit}
        validationSchema={allocateSlotSchema}
        render={({ touched, errors, handleChange }) => (
          <>
            <Content>
              <FormField>
                <label>Slot Start Date Time</label>
                <Input name='slot_start_date_time' placeholder='Slot Start Date Time' type='datetime-local' onChange={e => {
                  handleChange(e);
                  handleOnSlotSelect(e);
                }} />
                {touched.slot_start_date_time && errors.slot_start_date_time && <SyledError>Select date and time</SyledError>}
              </FormField>
              <FormField>
                <label>Slot Duration</label>
                <Input name='slot_duration' placeholder='Slot Duration' type='number' />
                {touched.slot_duration && errors.slot_duration && <SyledError>Select slot duration</SyledError>}
              </FormField>
              <FormField>
                <label>Location (https://maps.google.com/)</label>
                <Input name='location' placeholder='Enter the Google Maps location' type='text' />
                {touched.location && errors.location && <SyledError>Select location</SyledError>}
              </FormField>
            </Content>
            <Actions>
              <div>
                <Button type='submit'>Allocate Slot</Button>
                <Button
                  variant='text'
                  color='secondary'
                  onClick={props.onClose}
                >
                  Cancel
                </Button>
              </div>
            </Actions>
          </>
        )}
      />
      <AlertNotifier {...alert} open={alertOpen} onClose={handleCloseAlert} position={alertPosition} />
    </Dialog>
  );
}
