import { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Fade, IconButton, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledFade = styled(Fade)({
  outline: 'none',
});

const StyledContainer = styled('div')({
  position: 'relative',
  cursor: 'pointer',
  '& img': {
    transition: '0.3s ease-out',
  },
  '&:hover img': {
    filter: 'brightness(0.5)',
  },
  width: '300px',
  height: '300px',
});

const StyledImg = styled('img')({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});

const CloseButton = styled(IconButton)({
  position: 'absolute',
  top: '32px',
  right: '32px',
});

const ImageModal = ({ src, alt, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Tooltip title='Click to view the image' placement='right'>
        <StyledContainer onClick={handleOpen}>
          <StyledImg src={src} alt={alt} {...props} style={{ height: '200px', width: '120px' }} />
        </StyledContainer>
      </Tooltip>
      <StyledModal
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <div>  
          <Tooltip title='close'>
            <CloseButton onClick={handleClose}>
              <CancelRoundedIcon
                sx={{
                  width: 32,
                  height: 32,
                  cursor: 'pointer',
                  color: 'white',
                }}
              />
            </CloseButton>
          </Tooltip>
          <StyledFade in={open} timeout={500}>
            <img
              src={src}
              alt={alt}
              style={{ height: '90vh' }}
            />
          </StyledFade>
        </div>
      </StyledModal>
    </>
  );
};

export default ImageModal;
