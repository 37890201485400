import { Snackbar, Alert } from '@mui/material';

type Severity = 'error' | 'success' | 'info' | 'warning' | undefined;
export type Position = {
  vertical : 'top' | 'bottom'
  horizontal : 'left' | 'right' | 'center'
}
type AlertNotifierProps = {
  message: string;
  severity: Severity; 
  duration?: number;
  open: boolean; 
  onClose: () => void; 
  position?: Position
};

const AlertNotifier = (props: AlertNotifierProps) => {

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    props.onClose();
  };

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.duration || 3000}
      onClose={handleClose}
      anchorOrigin={props.position || { vertical: 'bottom', horizontal: 'left' }}
    >
      <Alert
        onClose={handleClose}
        variant='filled'
        severity={props.severity}
        sx={{ width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertNotifier;
