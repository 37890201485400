import { AxiosResponse } from 'axios';
import { AllocateSlotResponse, Registration, RegistrationListResponse } from 'models/Registration';
import { AllocateSlotParams } from 'views/dialogs/AllocateSlot';
import { getRequest, putRequest, sendFileRequest, sendRequest } from './gateway';


export interface ApiParams {
  query?: string;
  page?: number;
  limit?: number;
  name?: string,
  location?: string,
  phonenumber?: string,
  referalCode?: string,
  contestentId?: string,
  status?: string;
}

function fetchRegistrations(params: ApiParams): Promise<any> {
  const fetchPromise = getRequest<RegistrationListResponse>('/v1/register/list', { params });
  return sendRequest(fetchPromise);
}

function allocateSlot(params: AllocateSlotParams): Promise<any> {
  const fetchPromise = putRequest<AllocateSlotResponse>('/v1/register/slot-allocation', params);
  return sendRequest(fetchPromise);
}

function checkSlotAvailability(slotStartDateTime: string): Promise<any> {
  const fetchPromise = getRequest<RegistrationListResponse>(`/v1/register/slot-count/${slotStartDateTime}`);
  return sendRequest(fetchPromise);
}

function fetchRegistrationDetail(registration_id: string) {
  const fetchPromise = getRequest<Registration>(
    `/v1/register/${registration_id}`
  );

  return sendRequest(fetchPromise);
}

function updatePaymentStatus(
  registration_id: string,
  is_payment_received: boolean
) {
  const putPromise = putRequest<any>(`/v1/register/${registration_id}`, {
    is_payment_received,
  });

  return sendRequest(putPromise);
}

function updateSelectCandidate(
  registration_id: string,
  is_selected: string,
  comment: string
) {
  const putPromise = putRequest<any>(`/v1/register/select-candidate/${registration_id}`, {
    is_selected,
    comment
  });

  return sendRequest(putPromise);
}

function fetchSummary(): Promise<any> {
  const fetchPromise = getRequest<RegistrationListResponse>('/v1/register/registration-summary');
  return sendRequest(fetchPromise);
}

function getRegistrationReport(params: ApiParams): Promise<AxiosResponse<string, any>> {
  const fetchPromise = getRequest<string>('/v1/register/download-csv', {params});
  return sendFileRequest(fetchPromise);
}

export { allocateSlot, checkSlotAvailability, fetchRegistrationDetail, fetchRegistrations, fetchSummary, getRegistrationReport, updatePaymentStatus, updateSelectCandidate };

