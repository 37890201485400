import {  RegistrationListResponse } from 'models/Registration';
import { getRequest, sendRequest } from './gateway';
import { TicketApiParams } from 'models';


function fetchTickets(params: TicketApiParams): Promise<any> {
  const fetchPromise = getRequest<RegistrationListResponse>('/v1/ticket/list',{params});
  return sendRequest(fetchPromise);
}

function fetchTicket(ticketId: string): Promise<any> {
  const fetchPromise = getRequest(`/v1/ticket/${ticketId}`);
  return sendRequest(fetchPromise);
}

export { fetchTickets, fetchTicket };
