import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { ContestantDetail } from 'views/pages/registration/ContestantDetail';
import { ContestantProfile } from 'views/pages/registration/ContestantProfile';
import RegistrationList from 'views/pages/registration/registrationList';
import { ScanContestant } from 'views/pages/registration/ScanContestant';
import {TicketDetailView} from 'views/pages/tickets/TicketDetailView';
import TicketList from 'views/pages/tickets/TicketList';

import Layout from 'views/shared/layout';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      <Route index element={<Navigate to="/registrations" replace />} />
      <Route path='/registrations' element={<RegistrationList />} />
      <Route path='/registration/:registration_id/view' element={<ContestantDetail />} />
      <Route path='/registration/profile/:registration_id' element={<ContestantProfile />} />
      <Route path='/scan' element={<ScanContestant />} />
      <Route path='/tickets' element={<TicketList/>} />
      <Route path='/ticket/:ticket_id' element={<TicketDetailView />} />
    </Route>
  )
);

export { router };
