import { fetchTickets, fetchTicket } from 'gateways/ticket';
import { emptyTicket, Ticket, TicketApiParams, TicketListView } from 'models';
import { create } from 'zustand';
import { EntityResponse } from 'views/shared/utils/registration-utils';
import { handleApiError } from 'views/shared/utils/error-utils';

type Action = {
  fetchTickets: (params?: TicketApiParams) => Promise<EntityResponse<Ticket[]>>;
  sortTickets: (field: string, order: string) => void;
  fetchTicket: (ticketId: string) => Promise<EntityResponse<Ticket>>;
};

interface TicketPageView {
  TicketPageFilter: TicketApiParams;
  TicketList: TicketListView[];
  // hasNextPage: boolean;
}

const emptyTicketView = {
  TicketPageFilter: {},
  TicketList: []
};

type State = {
  ticketData: TicketPageView;
  ticket:Ticket
};

const useTicketStore = create<State & Action>()(set => ({
  ticketData: emptyTicketView,
  ticket: emptyTicket,

  fetchTickets: async (params) => {
    params.limit ??= 10;
    params.page ??= 1;
    const {
      failed,
      error,
      data: response,
    } = await handleApiError<any>(fetchTickets(params));
    if (failed) {
      return { failed, error, data: response };
    }
    const collection = Array.isArray(response?.data) ? response?.data : [];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    set(state => {
      const updatedTicketList =
        params.page === 1
          ? collection
          : state.ticketData.TicketList.concat(collection); 

      const data = {
        TicketPageFilter: params, 
        TicketList: updatedTicketList, 
      };

      return { ticketData: data };
    });
  },
  sortTickets: (field: string, order: string) => {
    set(state => {
      const { ticketData } = state;
      const sortedList = [...ticketData.TicketList].sort((a, b) => {
        if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
        return 0;
      });

      return { ticketData: { ...ticketData, TicketList: sortedList } };
    });
  },

  fetchTicket: async(ticketId) => {
    const {failed,error,data: response} = await handleApiError<any>(fetchTicket(ticketId));
    if (failed) {
      return { failed, error, data: response };
    }
    set({ ticket: response });
  },

}));

export { useTicketStore };
