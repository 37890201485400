import {
  ApiParams,
  fetchRegistrations
} from 'gateways/registration';

import { Registration, RegistrationListView } from 'models';
import { create } from 'zustand';

import {
  EntityResponse
} from 'views/shared/utils/registration-utils';
import { handleApiError } from 'views/shared/utils/error-utils';

type Action = {
    fetchRegistrations: (params: ApiParams) => Promise<EntityResponse<Registration[]>>;
    setSelectedRegistration: (registration: Registration[]) => void
    sortRegistration: (field: string, order: string) => void;
    setStatusFilter: (status: string | null) => void;
}

interface RegistrationPageView {
    registrationPageFilter: ApiParams;
    registrationList: RegistrationListView[];
    hasNextPage: boolean;
    fetched: boolean;
    statusFilter: string;
  }

const emptyRegistrationView = {
  registrationPageFilter: {},
  registrationList: [],
  hasNextPage: false,
  fetched: false,
  statusFilter: '',
} as RegistrationPageView;

  type State = {
    hasNextPage: boolean,
    registrationData: RegistrationPageView;
    selectedRegistration: Registration[];
    statusFilter: string;
  };

const useRegistrationStore = create<State & Action>()((set) => ({
  hasNextPage: false,
  registrationData: emptyRegistrationView,
  selectedRegistration: [],
  statusFilter: '',
  fetchRegistrations: async (params: ApiParams) => {
    params.limit ??= 10;
    params.page ??= 1;
    const { failed, error, data: response } = await handleApiError<any>(fetchRegistrations(params));
    if (failed) {
      return { failed, error, data: response};
    }
    const collection = Array.isArray(response?.data) ? response?.data : [];
    
    set(state => {
      const registrationList =
                params.page === 1
                  ? collection
                  : state['registrationData'].registrationList.concat(collection);

      const data = {
        registrationPageFilter: params,
        hasNextPage: params.page < Math.ceil(response.total / response.limit),
        registrationList: registrationList,
        fetched: true,
        statusFilter: params.status
      };

      return { registrationData: data };
    });
  },
  setSelectedRegistration: (registration: Registration[]) => {
    set({ selectedRegistration: registration });
  },
  sortRegistration: (field: string, order: string) => {
    set(state => {
      const { registrationData } = state;
      const sortedList = [...registrationData.registrationList].sort((a, b) => {
        if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
        if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
        return 0;
      });

      return { registrationData: { ...registrationData, registrationList: sortedList } };
    });
  },
  setStatusFilter: (statusFilter) => set({statusFilter})
}));

export{useRegistrationStore};

