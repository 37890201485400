export type EntityResponse<T> = {
  error: string;
  data: T;
  failed: boolean;
};

const handleApiError = async <T>(apiCall: any): Promise<EntityResponse<T>> => {
  const response: any = await apiCall;
  return {
    failed: Boolean(!response?.succeeded && response?.errors?.[0]?.description),
    error: response?.errors?.[0]?.description,
    data: response,
  };
};

export {
  handleApiError,
};
