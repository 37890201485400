import {
  IconButton,
  TextField,
  FormLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Button } from 'views/components/elements';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form } from 'formik';
import { ApiParams } from 'gateways/registration';
import { FlexColumn, FlexRow } from '../styled';
import { getAdvancedSearchFields, getAdvancedSearchInitialValues } from 'shared/utils/advanceSearchUtils';
import { ticketCategory } from 'models';

const Head = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 24,
});

const FieldContainer = styled(FlexColumn)({
  padding: '10px 50px',
  alignItems:'normal'
});

const StyledFieldContainer = styled(FlexRow)({
  justifyContent: 'space-between',
  columnGap: '70px',
});

const StyledFormLabel = styled(FormLabel)({
  color: 'black',
});

const StyledTextField = styled(TextField)({
  backgroundColor: 'white',
  borderRadius: '4px',
  
});

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  padding: '16px 24px',
});


type Props = {
  open: boolean;
  onClose: () => void;
  onSearch: (value: any) => void;
  module: 'ticket' | 'registration'
};

function AdvanceSearchDialog(props: Props) {
  // Create initial values based on the dynamic fields
  const fields = getAdvancedSearchFields(props.module);
  const initialData = getAdvancedSearchInitialValues(props.module);
  const initialValues = fields.reduce((acc, field) => {
    acc[field.name] = initialData[field.name]; 
    return acc;
  }, {} as Record<string, string>);

  const handleSubmit = async (values: ApiParams) => {
    const params = Object.fromEntries(
      Object.entries(values).map(([key, value]) => {
        if (key === 'ticket_category' && ticketCategory[value]) {
          return [key, ticketCategory[value]];
        }
        return [key, value];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      }).filter(([key, value]) => value !== '') 
    );
    props.onSearch(params);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={false}
      fullWidth={true}
      PaperProps={{
        sx: { width: '38%', maxWidth: '1000px', padding: '10px' },
      }}
    >
      <Head>
        <DialogTitle>Search Registrations</DialogTitle>
        <IconButton onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </Head>

      <Formik initialValues={initialValues} onSubmit={handleSubmit} key={props.module} enableReinitialize>
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <FieldContainer>
              {fields.map((field) => (
                <StyledFieldContainer key={field.name}>
                  <StyledFormLabel>{field.label}</StyledFormLabel>
                  {field.name === 'ticket_category' ? (
                    <Select
                      size="small"
                      value={values.ticket_category}
                      onChange={(e) => setFieldValue('ticket_category', e.target.value)}
                      id="ticket_category"
                      name="ticket_category"
                      displayEmpty
                    >
                      <MenuItem value="">
                        Select Ticket Category
                      </MenuItem>
                      {Object.entries(ticketCategory).map(([category, number]) => (
                        <MenuItem key={number} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    // Render TextField for all other fields
                    <StyledTextField
                      size="small"
                      type={field.type || 'text'}
                      onChange={handleChange}
                      value={values[field.name]}
                      id={field.name}
                      name={field.name}
                      placeholder={`Search for ${field.label.toLowerCase()}`}
                    />
                  )}
                </StyledFieldContainer>
              ))}
            </FieldContainer>

            <Actions>
              <Button type='submit'>Search</Button>
              <Button variant='text' color='secondary' onClick={props.onClose}>
                Cancel
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default AdvanceSearchDialog;
