import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Ticket } from 'models';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTicketStore } from 'store';
import { Button } from 'views/components/elements';
import ImageModal from 'views/components/ImageModal';
import { FlexColumn, FlexRow } from 'views/components/styled';
import { Actions } from 'views/shared/StyledComponents';

const StyledFlexColumn = styled(FlexColumn)({
  height: '100%',
});

const StyledBox = styled(Box)({
  overflowY: 'scroll',
});

const DetailsContainerBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '150px 1fr',
  color: '#475569',
  gap: '0.8rem',
  ['& .key']: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  ['& .value']: {
    textTransform: 'capitalize',
  },
});

const TicketDetailView = () => {
  const { fetchTicket, ticket } = useTicketStore();
  const navigate = useNavigate();
  const { ticket_id } = useParams();

  const handleRedirect = (type: 'back') => () => {
    switch (type) {
    case 'back':
      navigate('/tickets');
      break;
    }
  };

  useEffect(() => {
    if (!(ticket?.ticket_id === ticket_id)) {
      fetchTicket(ticket_id);
    }
  }, [ticket_id]);

  const ImagePreview = ({ src, alt, message }) => (
    <Box>
      {src ? (
        <ImageModal src={src} alt={alt} />
      ) : (
        <Box sx={{ ml: 3 }}>{message}</Box>
      )}
    </Box>
  );

  const renderDetails = (
    fields: (keyof Ticket)[],
    exclude: boolean,
    ticket: Ticket
  ): React.ReactNode => {
    return (
      <DetailsContainerBox>
        {Object.entries(ticket).map(([key, value]) => {
          const shouldRender = exclude
            ? !fields.includes(key as keyof Ticket)
            : fields.includes(key as keyof Ticket);
          if (!shouldRender) return null;

          if (['phone'].find(field => key === field)) {
            key = 'Phone No';
          }

          if (['total_amount'].find(field => key === field)) {
            value = `₹ ${value}/-`;
          }

          if (key === 'venue') {
            value = (
              <Link to={value as string} target='_blank'>
                Venue
              </Link>
            ) as any;
          }

          return (
            <React.Fragment key={key}>
              <span className='key'>{key.replace(/_/g, ' ')}</span>
              <span className='value'>
                :{' '}
                {typeof value === 'boolean'
                  ? value
                    ? 'Yes'
                    : 'No'
                  : value || '-'}
              </span>
            </React.Fragment>
          );
        })}
      </DetailsContainerBox>
    );
  };

  const getTicketDetails = () => {
    const fieldsToRemove: (keyof Ticket)[] = [
      'UPI_transaction_id',
      'is_payment_received',
      'payment_proof_url',
      'photo_url',
      'ticket_id',
      'QR_code_url',
      'user_id',
    ];
    if (ticket.ticket_collection_type !== 'Delivery') {
      fieldsToRemove.push('ticket_delivery_address');
    }
    return renderDetails(fieldsToRemove, true, ticket);
  };

  return (
    <StyledFlexColumn>
      {ticket?.name ? (
        <>
          <FlexRow>
            <FlexColumn style={{ flex: '0 0 50%' }}>
              <StyledBox>{getTicketDetails()}</StyledBox>
              <Actions>
                <Button
                  variant='text'
                  sx={{
                    border: '2px solid #b6b4b4',
                    width: '130px',
                  }}
                  color='secondary'
                  onClick={handleRedirect('back')}
                >
                  Back
                </Button>
              </Actions>
            </FlexColumn>
            <ImagePreview
              src={ticket.photo_url}
              alt={`${ticket.name}'s profile image`}
              message={`User(${ticket.name}) profile is missing.`}
            />
          </FlexRow>
        </>
      ) : (
        <></>
      )}
    </StyledFlexColumn>
  );
};

export { TicketDetailView };

