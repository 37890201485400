import GroupIcon from '@mui/icons-material/Group';
import PaymentIcon from '@mui/icons-material/Payment';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { FlexColumn, FlexRow } from 'views/components/styled';
import { ReactNode } from 'react';

interface RegistrationAggregationData {
    total_number_of_registrations: number;
    not_verified_registrations: number;
    payment_received: number;
    payment_not_received: number;
    slot_allocated_registrations: number;
    slot_not_allocated_registrations: number;
}

interface DashBoardProps {
    data?: RegistrationAggregationData;
    onSelectionChange: (key: string) => void;
}

interface DashBoardFieldProps {
    field: keyof RegistrationAggregationData;
    value: string;
    onClick: () => void;
}

const DashBoardListTag = styled(FlexRow)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  width: '100%'
});

const DashBoardFieldTag = styled(DashBoardListTag)({
  ['&:hover']: {
    cursor: 'pointer',
  }
});

const DashBoardIconHolderTag = styled(Box)({
  background: '#eee',
  padding: '0.6rem',
  borderRadius: '5px',
});

const DashBoardContentTag = styled(FlexColumn)({
  padding: '0.3rem',
  gap: '0.8rem',
  ['& h6']: {
    lineHeight: 0,
    margin: 0,
  }
});

const getDashboardConfig = (key: keyof RegistrationAggregationData): {
    label: string;
    icon?: ReactNode,
} => {
  switch (key) {
  case 'total_number_of_registrations':
    return {
      icon: <DashBoardIconHolderTag sx={{ backgroundColor: '#B9D9EB' }}>
        <GroupIcon sx={{ color: '#003153' }} />
      </DashBoardIconHolderTag>,
      label: 'Total Registrations',
    };
  case 'not_verified_registrations':
    return {
      icon: <DashBoardIconHolderTag sx={{ backgroundColor: '#FBCEB1' }}>
        <ErrorOutlineIcon sx={{ color: '#D99058' }} />
      </DashBoardIconHolderTag>,
      label: 'Payment Verifications'
    };
  case 'payment_received':
    return {
      icon: <DashBoardIconHolderTag sx={{ backgroundColor: '#ACE1AF' }}>
        <PaymentIcon sx={{ color: '#00693E' }} />
      </DashBoardIconHolderTag>,
      label: 'Payment Received'
    };
  case 'payment_not_received':
    return {
      icon: <DashBoardIconHolderTag sx={{ backgroundColor: '#FFE4E1' }}>
        <RemoveCircleOutlineIcon sx={{ color: '#ED2839' }} />
      </DashBoardIconHolderTag>,
      label: 'Payment Pending'
    };
  case 'slot_allocated_registrations':
    return {
      icon: <DashBoardIconHolderTag sx={{ backgroundColor: '#ACE1AF' }}>
        <EventAvailableIcon sx={{ color: '#00693E' }} />
      </DashBoardIconHolderTag>,
      label: 'Slot Allocated'
    };
  case 'slot_not_allocated_registrations':
    return {
      icon: <DashBoardIconHolderTag sx={{ backgroundColor: '#F1DDCF' }}>
        <EventBusyIcon sx={{ color: '#DE3163' }} />
      </DashBoardIconHolderTag>,
      label: 'Slot Allotment Pending'
    };
  }
};

const DashBoardField: React.FC<DashBoardFieldProps> = ({ field, value, onClick }) => {
  const dashboardConfig = getDashboardConfig(field);
  return (
    <DashBoardFieldTag onClick={onClick}>
      <span>
        {dashboardConfig.icon}
      </span>
      <DashBoardContentTag>
        <span style={{fontSize: '16px', fontWeight: 'bold'}}>{dashboardConfig.label}</span>
        <strong>{value}</strong>
      </DashBoardContentTag>
    </DashBoardFieldTag>
  );
};

const DashBoardList: React.FC<DashBoardProps> = ({data, onSelectionChange}) => {
  return <DashBoardListTag>
    {
      Object.entries(data).map(([key, value])=> {
        return <DashBoardField key={key} field={key as any} value={value} onClick={()=>onSelectionChange(key)} />;
      })
    }
  </DashBoardListTag>;
};

export { DashBoardList };
