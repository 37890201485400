import styled from '@emotion/styled';
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { fetchRegistrationDetail, updateSelectCandidate } from 'gateways/registration';
import { Registration } from 'models';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import WarningCard from 'views/components/elements/WarningCard';
import { FlexColumn } from 'views/components/styled';
import { handleApiError } from 'views/shared/utils/error-utils';
import { dateTimeFormat } from 'views/shared/utils/string-utils';

const StyledFlexColumn = styled(FlexColumn)({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  overflowY: 'auto',
});

const StyledProfileContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '150px',
});

const StyledStack = styled(Stack)({
  width: '100%',
});

const StyledBox = styled(Box)({
  flexBasis: '100%',
  overflowY: 'scroll',
});


const DetailsContainerBox = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '100px 1fr',
  color: '#475569',
  gap: '0.8rem',
  ['& .key']: {
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  ['& .value']: {
    textTransform: 'capitalize'
  },
});

const StyledButton = styled(Button)({
  margin: '10px'
});

export const ContestantProfile = () => {

  const { registration_id } = useParams();
  const [contestantDetail, setContentantDetail] =
    useState<Partial<Registration>>(null);
  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isInitialFetchCompleted = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [comments, setComments] = useState('');
  const [radioSelection, setRadioSelection] = useState('');

  const handleError = (error: string) => {
    setErrorMessage(error);
    setIsErrorPopUpOpen(true);
  };

  function checkDateAndTime(inputDateTime) {
    const givenDateTime = moment(inputDateTime);
    const now = moment();
    const oneHourAgo = now.add(1, 'hour');
    return givenDateTime.isSameOrBefore(oneHourAgo);
  }

  const fetchData = async () => {
    const { failed, data } = await handleApiError<Registration>(
      fetchRegistrationDetail(registration_id)
    );

    if (failed) {
      return handleError(
        'Failed to fetch the details. Please refresh the page'
      );
    }

    setContentantDetail({
      ...data,
      dob: dateTimeFormat(data.dob, 'date') as string,
      slot_start_date_time: dateTimeFormat(
        data.slot_start_date_time,
        'datetime'
      ) as string,
      created_date_time: dateTimeFormat(
        data.created_date_time,
        'datetime'
      ) as string,
      updated_date_time: dateTimeFormat(
        data.updated_date_time,
        'datetime'
      ) as string,
    });

    switch (data.is_selected) {
    case 'Selected':
      setRadioSelection('selected');
      break;
    case 'Not Selected':
      setRadioSelection('notSelected');
      break;
    case 'Waiting List':
      setRadioSelection('waitingList');
      break;
    default:
      setRadioSelection('');
    }

    if (checkDateAndTime(data.slot_start_date_time)) {
      setIsButtonDisabled(false);
    }

    if (data.is_selected === 'Not Selected') {
      setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      fetchData();
      isInitialFetchCompleted.current = true;
    }
  }, []);

  const renderDetails = (
    fields: (keyof Registration)[],
    isMarried: boolean,
    exclude: boolean
  ) => {
    return (
      <DetailsContainerBox>
        {Object.entries(contestantDetail).map(([key, value]) => {
          const shouldRender = exclude
            ? !fields.includes(key as keyof Registration)
            : fields.includes(key as keyof Registration);
          if (!shouldRender) return null;

          if (isMarried && ['parent_phone', 'parent_name'].includes(key)) {
            key = key.replace('parent', 'guardian');
          }

          if ('created_date_time' === key) {
            key = key.replace('created', 'registered');
          }

          if (key === 'venue') {
            value = <Link to={value as string} target='_blank'>Venue</Link> as any;
          }

          return (
            <>
              <span className='key'>{key.replace(/_/g, ' ')}</span>
              <span className='value'>
                :{' '}
                {typeof value === 'boolean'
                  ? value
                    ? 'Yes'
                    : 'No'
                  : value || '-'}
              </span>
            </>
          );
        })}
      </DetailsContainerBox>
    );
  };

  const getContestantDetails = () => {
    const fieldsToRemove: (keyof Registration)[] = [
      'UPI_transaction_id',
      'is_payment_received',
      'payment_proof_url',
      'photo_link',
      'registration_id',
      'slot_duration',
      'slot_start_date_time',
      'updated_date_time',
      'contestant_id',
      'venue',
      'marital_status',
      'address',
      'native',
      'email_id',
      'professional',
      'parent_name',
      'parent_phone',
      'created_date_time',
      'referral_code',
      'instagram_id'
    ];
    const isMarried =
      contestantDetail.marital_status?.toLowerCase() === 'married';

    return renderDetails(fieldsToRemove, isMarried, true);
  };

  const handleOnSumit = async () => {
    await updateSelectCandidate(contestantDetail.registration_id, radioSelection, comments);
    fetchData();
  };

  return (
    <StyledFlexColumn>
      <h2>
        Contestant Profile
      </h2>

      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
      />

      {
        contestantDetail?.name ? (
          <StyledProfileContainer>
            <img
              src={contestantDetail.photo_link}
              alt={`${contestantDetail.name}'s profile image`}
              style={{ height: '200px', width: '120px' }}
            />

            <div>
              <StyledStack>
                <StyledBox>{getContestantDetails()}</StyledBox>
              </StyledStack>
            </div>

            <TextField
              label="Comments"
              multiline
              rows={1}
              variant="standard"
              fullWidth
              value={comments}
              onChange={e => setComments(e.target.value)}
            />

            <RadioGroup
              row
              value={radioSelection}
              onChange={(e) => setRadioSelection(e.target.value)}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <FormControlLabel value="selected" control={<Radio />} label="Selected" disabled={isButtonDisabled} />
              <FormControlLabel value="waitingList" control={<Radio />} label="Waiting List" disabled={isButtonDisabled} />
              <FormControlLabel value="notSelected" control={<Radio />} label="Not Selected" disabled={isButtonDisabled} />
            </RadioGroup>

            <StyledButton
              variant='contained'
              color='info'
              disabled={isButtonDisabled}
              onClick={handleOnSumit}
            >
              Save Changes
            </StyledButton>
          </StyledProfileContainer>
        ) : <></>
      }
    </StyledFlexColumn>
  );
};
