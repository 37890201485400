import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useAccountStore, useSpinnerStore } from 'store';
import { login } from 'services/AccountService';
import TopNavigationBar from 'views/components/nav/TopNavigationBar';
import SideNavigationBar from 'views/components/nav/SideNavigationBar';
import './index.scss';
import { FlexColumn } from 'views/components/styled';

function Layout() {
  const { isAuthenticated } = useAccountStore();
  const { spinnerShown } = useSpinnerStore();
  const isInitialFetchCompleted = useRef(false);

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      login();
      isInitialFetchCompleted.current = true;
    }
  }, []);

  if (!isAuthenticated) {
    return (
      <>
        <FlexColumn align='center' size='xl'>
          <iframe
            title='bff-silent-login'
            id='bff-silent-login'
            hidden
          ></iframe>
          <img
            className='logo'
            src='/logo.png'
            alt='logo'
            style={{ marginTop: '10%' }}
          />
          <h1>Authenticating to Aveon Services</h1>
        </FlexColumn>
        {spinnerShown && (
          <div className='spinner'>
            <CircularProgress />
          </div>
        )}
      </>
    );
  }

  return (
    <div className='container'>
      <TopNavigationBar />
      <div className='content-wrapper'>
        <SideNavigationBar />
        <div className='content'>
          <Outlet />
        </div>
      </div>
      {spinnerShown && (
        <div className='spinner'>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default Layout;
