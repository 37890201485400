import { useAccountStore } from 'store/account';
import MaterialIconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import './TopNavigationBar.scss';
import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import { FlexRow } from '../styled';
import { useNavigate } from 'react-router-dom';

const IconButton = styled(MaterialIconButton)({ padding: 0 });

const menuProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

function ProfileMenu() {
  const navigate = useNavigate();
  const { user } = useAccountStore();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfile = () => {
    navigate('./registrations');
    handleClose();
    window.location.replace(user.authority);
  };
  const handleLogout = () => {
    handleClose();
    window.location.replace(user.logoutUrl);
  };

  return (
    <>
      <Tooltip title='Account settings'>
        <IconButton
          size='small'
          disableRipple
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
        >
          <div className='avatar'>{user?.avatarText}</div>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfile}>
          <Avatar /> Profile
        </MenuItem>
        {/* <MenuItem onClick={handleProfile}>
          <Avatar /> My account
        </MenuItem> */}
        <Divider />
        <MenuItem onClick={() => navigate('/registrations')}>
          Registrations
        </MenuItem>
        <MenuItem onClick={() => navigate('/scan')}>
          Scan Profile
        </MenuItem>
        <MenuItem onClick={handleLogout} href={user?.logoutUrl}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

function TopNavigationBar() {
  return (
    <div className='nav-container'>
      <div className='nav-left'>
        <div className='brand'>
          <img className='logo' src='/logo.png' alt='logo' />
        </div>
      </div>
      <FlexRow>
        <ProfileMenu />
      </FlexRow>
    </div>
  );
}

export default TopNavigationBar;
